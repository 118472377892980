import React, {useEffect, useState} from "react";

import s from "./Main.module.css";
import SliderActions from "../../libs/SliderActions";
import clsx from "clsx";
import Catigories from "./Catigories";
import MainCatigories from "./MainCatigories";
import Recommendation from "./Recommendation";
import SliderHit from "./SliderHit";
import AllProduct from "./AllProduct";
import Discount from "./Discount";
import {SearchForm} from "../../components/SearchForm";
import TableId from "../../hooks/tableId";
import RecomendationItem from "../../components/ModalFormItem/RecomendationItem";
import {useGetLocationData, useSetLocationData} from "../../hooks/locationData";
import Layout from "../../components/Layout";
import Icon from "../../components/Icon";
import {TabsCat} from "../../components/TabsCat";
import useObjectModal from "../../hooks/useObjectModal";


const Main = () => {
    // const [activeDrink, setActiveDrink] = useState(1)
    const { searchText, modal } = useGetLocationData();
    const setData = useSetLocationData();
    const [table,setTable] = useState<string>("")
    const { objectModal, addObjectModalItem } = useObjectModal(searchText);
    useEffect(() => {
        const savedData = localStorage.getItem('tableId');
        if (savedData) {
            setTable(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tableValue = urlParams.get('table');
        if(tableValue) {
            setTable(`${tableValue}`)
        }
    }, []);

    useEffect(() => {
        if(!!table){
            localStorage.setItem('tableId', JSON.stringify(table));
        }
    }, [table]);


        const handleClickRes = (id:number) => {
            addObjectModalItem(id,1)
    };


    return (
        <Layout>
        <div className={s.wrapper}>
            <div className={s.borderBottem}>
            <SearchForm  handleClickRes={handleClickRes} objectModal={objectModal}  />


            <div className={s.slider}>
                <SliderActions/>
            </div>


            <Catigories />
            </div>
            <MainCatigories />
            <Discount handleClickRes={handleClickRes} objectModal={objectModal} />
            {/*<Recommendation handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink}/>*/}
            {/*<SliderHit handleClickRes={handleClickRes} objectModal={objectModal} active={activeDrink}/>*/}
            <AllProduct handleClickRes={handleClickRes} objectModal={objectModal} />
        </div>
        </Layout>
    )

}

export default Main